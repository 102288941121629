'use strict'

angular
  .module 'mundoLive'
  .config ($stateProvider) ->
    $stateProvider
      .state 'live',
        url: '/live'
        templateUrl: 'mundo-live/views/mundo-live.tpl.html'
        controller: 'MundoLiveCtrl'
        controllerAs: 'liveCtrl'
        data: {
          navigation: true
        }
        onEnter: [
          '$window'
          '$rootScope'
          '$log'
          'LiveSubscriptionService'
          (
            $window,
            $rootScope,
            $log
            LiveSubscriptionService
          ) ->
            $rootScope.liveActive = true
        ]
        onExit: [
            '$window'
            '$rootScope'
            'MundoNavigation'
            'MundoSocket'
            'LiveSubscriptionService'
          (
            $window,
            $rootScope,
            MundoNavigation,
            MundoSocket
            LiveSubscriptionService
          ) ->
            delete $rootScope['liveActive']
            LiveSubscriptionService.disable()
            $rootScope.subscriptionListener()
        ]
